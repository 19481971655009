var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "relative", staticStyle: { height: "115px" } },
    [
      _c("WeveTextFieldButtonGroup", {
        attrs: {
          label: "Meeting Announcement",
          placeholder: "e.g. The game will be ending in 10 minutes",
          value: _vm.anouncement,
          rules: "max:150",
          maxlength: "150",
          "characters-counter": "",
        },
        on: { add: _vm.saveAnnouncement },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }