var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "add-activity-panel pt-2",
      staticStyle: { "background-color": "#1e2025", height: "100%" },
    },
    [
      _c(
        "div",
        [
          _vm._t("back-button"),
          _vm.quickAddEnabled
            ? _c(
                "v-layout",
                { attrs: { flex: "" } },
                [
                  _c(
                    "v-btn",
                    {
                      staticStyle: {
                        "min-width": "40px",
                        background: "rgba(255, 255, 255, 0.05)",
                      },
                      attrs: { color: "#3C3C3C" },
                      on: {
                        click: function ($event) {
                          return _vm.$emit("closeMenu")
                        },
                      },
                    },
                    [_c("v-icon", [_vm._v("close")])],
                    1
                  ),
                  _c("div", { staticClass: "ml-2" }, [
                    _c("h2", { staticClass: "white--text" }, [
                      _vm._v("Quick Add New Activity"),
                    ]),
                    _vm.currentMissionName
                      ? _c("p", { staticStyle: { color: "#9aa1ad" } }, [
                          _vm._v(
                            " Activity will be after ‘" +
                              _vm._s(_vm.currentMissionName) +
                              "’ "
                          ),
                        ])
                      : _vm._e(),
                  ]),
                ],
                1
              )
            : _vm._e(),
        ],
        2
      ),
      !_vm.quickAddEnabled
        ? [
            _c(
              "v-tabs",
              {
                attrs: {
                  "slider-color": "#6e69d1",
                  color: "rgb(30, 32, 37)",
                  dark: "",
                },
                model: {
                  value: _vm.addActivityTab,
                  callback: function ($$v) {
                    _vm.addActivityTab = $$v
                  },
                  expression: "addActivityTab",
                },
              },
              [
                _c(
                  "v-tab",
                  { key: "questions", staticClass: "add-activity-panel__tab" },
                  [_vm._v("Questions")]
                ),
                _c(
                  "v-tab",
                  { key: "utility", staticClass: "add-activity-panel__tab" },
                  [_vm._v("Utility")]
                ),
                _c(
                  "v-tab-item",
                  { key: "questions" },
                  [
                    _c("AddQuestions", {
                      on: {
                        showSubMenu: function ($event) {
                          return _vm.$emit("showSubMenu", $event)
                        },
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "v-tab-item",
                  { key: "utility" },
                  [_c("AddActivityPanelUtility")],
                  1
                ),
              ],
              1
            ),
          ]
        : _c(
            "div",
            { staticClass: "px-3 mt-3" },
            [
              _c("AddPictionary", { staticClass: "my-1" }),
              _c("AddMeetingAnnouncement", { staticClass: "my-1" }),
              _c("AddLinkUrl", { staticClass: "my-1" }),
              _c("AddYouTubeMission", { staticClass: "my-1" }),
            ],
            1
          ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }