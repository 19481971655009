var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "activity-list-panel" },
    [
      _c("ActivityPanelLocker"),
      _c("div", { staticClass: "activity-list-panel__header" }, [
        _vm._v("Add Activity Set"),
      ]),
      _vm.hasAdvancedControls
        ? [
            _c("div", { staticClass: "activity-list-panel__subtitle" }, [
              _vm._v(" Activity set will be added after current activity "),
            ]),
            _c(
              "WeveButton",
              {
                staticClass: "my-2",
                attrs: { size: "sm", variant: "primary", block: "" },
                on: {
                  click: function ($event) {
                    return _vm.$emit("showPanel", _vm.pannelNames.AddActivities)
                  },
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "prepend",
                      fn: function () {
                        return [
                          _c("v-icon", { staticClass: "black--text" }, [
                            _vm._v(" add_circle "),
                          ]),
                        ]
                      },
                      proxy: true,
                    },
                  ],
                  null,
                  false,
                  583982290
                ),
              },
              [_vm._v(" ADD NEW ACTIVITY ")]
            ),
            _c("hr", { staticClass: "divider my-3" }),
            _c(
              "div",
              { staticClass: "d-flex mb-3" },
              [
                _c(
                  "v-flex",
                  { attrs: { xs11: "", shrink: "" } },
                  [
                    _vm.userType !== _vm.UserType.PLAYER
                      ? _c("WeveTextField", {
                          attrs: {
                            label: "Search",
                            "hide-label": "",
                            placeholder: "Search...",
                          },
                          model: {
                            value: _vm.query,
                            callback: function ($$v) {
                              _vm.query = $$v
                            },
                            expression: "query",
                          },
                        })
                      : _vm._e(),
                  ],
                  1
                ),
                _c("v-flex", { attrs: { "d-flex": "", xs1: "", shrink: "" } }, [
                  _c(
                    "button",
                    { staticClass: "ml-2", on: { click: _vm.toggleFilters } },
                    [
                      _c(
                        "v-icon",
                        {
                          style: {
                            color: _vm.filters ? "#8680ff" : "#DADADA",
                          },
                        },
                        [_vm._v("filter_list")]
                      ),
                    ],
                    1
                  ),
                ]),
              ],
              1
            ),
            _vm.filters
              ? [
                  _c(
                    "v-layout",
                    {
                      staticClass: "flex-grow-0",
                      staticStyle: { "align-items": "flex-end" },
                      attrs: { flex: "" },
                    },
                    [
                      _c("WeveSelectField", {
                        staticClass: "mb-3",
                        staticStyle: { width: "100%" },
                        attrs: {
                          label: "Type",
                          placeholder: "Filter by type",
                          options: _vm.types,
                        },
                        model: {
                          value: _vm.type,
                          callback: function ($$v) {
                            _vm.type = $$v
                          },
                          expression: "type",
                        },
                      }),
                      _vm.type !== null
                        ? _c(
                            "v-btn",
                            {
                              staticClass: "xs1",
                              staticStyle: {
                                "min-width": "20px",
                                margin: "18px 5px",
                                padding: "0 10px",
                              },
                              attrs: { flat: "" },
                              on: {
                                click: function ($event) {
                                  _vm.type = null
                                },
                              },
                            },
                            [
                              _c("SvgIcon", {
                                attrs: { size: "small", name: "times-regular" },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                  _c(
                    "v-layout",
                    {
                      staticClass: "flex-grow-0 mb-2",
                      staticStyle: { position: "relative" },
                    },
                    [
                      _c(
                        "v-layout",
                        {
                          staticStyle: {
                            position: "absolute",
                            right: "0px",
                            top: "-20px",
                          },
                          attrs: { flex: "", "align-center": "" },
                        },
                        [
                          _c("div", { staticClass: "mr-2 pt-3" }, [
                            _vm._v("Mine"),
                          ]),
                          _c("v-switch", {
                            staticClass: "activity-list-panel__me-switch",
                            staticStyle: { height: "22.5px", padding: "0" },
                            attrs: { color: "#8680ff" },
                            model: {
                              value: _vm.mine,
                              callback: function ($$v) {
                                _vm.mine = $$v
                              },
                              expression: "mine",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-layout",
                        {
                          staticStyle: {
                            width: "100%",
                            "align-items": "flex-end",
                          },
                          attrs: { flex: "" },
                        },
                        [
                          _c("WeveSelectField", {
                            staticClass: "xl11 mb-1",
                            staticStyle: { width: "100%" },
                            attrs: {
                              placeholder: "Filter by user",
                              label: "Owner",
                              options: _vm.users,
                              "option-label": "fullname",
                              "option-value": "id",
                              getOptionKey: function (option) {
                                return option.id
                              },
                            },
                            on: { "search:focus": _vm.fetchUsers },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "option",
                                  fn: function (option) {
                                    return [
                                      option.image
                                        ? _c("img", {
                                            staticClass:
                                              "w-2 h-2 rounded-full mr-1 object-cover",
                                            attrs: {
                                              src: option.image,
                                              alt: option.label + " avatar",
                                            },
                                          })
                                        : _vm._e(),
                                      _c(
                                        "span",
                                        {
                                          staticClass: "relative",
                                          staticStyle: { top: "2px" },
                                        },
                                        [
                                          _vm._v(
                                            " " + _vm._s(option.fullname) + " "
                                          ),
                                        ]
                                      ),
                                    ]
                                  },
                                },
                                {
                                  key: "selected-option",
                                  fn: function (ref) {
                                    var option = ref.item
                                    return [
                                      option.image
                                        ? _c("img", {
                                            staticClass:
                                              "w-8 h-8 rounded-full mr-1 object-cover",
                                            attrs: {
                                              src: option.image,
                                              alt: option.label + " avatar",
                                            },
                                          })
                                        : _vm._e(),
                                      _c(
                                        "span",
                                        {
                                          staticClass: "relative",
                                          staticStyle: { top: "2px" },
                                        },
                                        [
                                          _vm._v(
                                            " " + _vm._s(option.fullname) + " "
                                          ),
                                        ]
                                      ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              996473092
                            ),
                            model: {
                              value: _vm.userID,
                              callback: function ($$v) {
                                _vm.userID = $$v
                              },
                              expression: "userID",
                            },
                          }),
                          _vm.userID !== null
                            ? _c(
                                "v-btn",
                                {
                                  staticClass: "xs1",
                                  staticStyle: {
                                    "min-width": "20px",
                                    margin: "5px",
                                    padding: "0 10px",
                                  },
                                  attrs: { flat: "" },
                                  on: {
                                    click: function ($event) {
                                      _vm.userID = null
                                    },
                                  },
                                },
                                [
                                  _c("SvgIcon", {
                                    attrs: {
                                      size: "small",
                                      name: "times-regular",
                                    },
                                  }),
                                ],
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]
              : _vm._e(),
          ]
        : _vm._e(),
      _c(
        "div",
        { staticClass: "activity-items mt-2" },
        _vm._l(_vm.activitySetsWithStatus, function (set) {
          return _c(
            "div",
            {
              key: set.id,
              staticClass: "activity-item",
              class: {
                "activity-item--played": set.played,
                "activity-item--imported": set.imported,
                "activity-item--available": !set.imported,
              },
              on: {
                click: function ($event) {
                  set.imported && _vm.navigateToSet(set.id)
                },
              },
            },
            [
              _c("div", { staticClass: "activity-item__duration" }, [
                _vm._v(" " + _vm._s(_vm.formatTime(set.duration)) + " "),
              ]),
              _c("div", { staticClass: "activity-item__name" }, [
                _vm._v(" " + _vm._s(set.name) + " "),
              ]),
              !set.imported
                ? _c(
                    "WeveButton",
                    {
                      staticClass: "activity-item__btn ml-1",
                      attrs: { size: "sm", variant: "primary" },
                      on: {
                        click: function ($event) {
                          !set.imported && _vm.importSet(set.id, set.name)
                        },
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "prepend",
                            fn: function () {
                              return [
                                _c("v-icon", { staticClass: "black--text" }, [
                                  _vm._v(" add_circle "),
                                ]),
                              ]
                            },
                            proxy: true,
                          },
                        ],
                        null,
                        true
                      ),
                    },
                    [_vm._v(" ADD ")]
                  )
                : _c("SvgIcon", {
                    staticClass:
                      "mr-2 red--text cursor-pointer activity-item__trash-icon",
                    staticStyle: { cursor: "pointer" },
                    attrs: { name: "replay" },
                    on: {
                      click: function ($event) {
                        $event.stopPropagation()
                        return _vm.restartSet(set.id)
                      },
                    },
                  }),
            ],
            1
          )
        }),
        0
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }