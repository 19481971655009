





















































import { ValidationObserver } from "vee-validate"
import { mapGetters } from "vuex"

import { WeveButton } from "@weve/ui"
import WeveTextFieldWithValidation from "@/components/WeveTextFieldWithValidation.vue"
import WeveTextFieldButtonGroup from "@/components/WeveTextFieldButtonGroup.vue"

import { countCharacters } from "@/utils/characters"

import AddMissionMixin from "@/components/ActivityMenuPanels/AddMissionMixin"

import AddPictionary from "./Fields/AddPictionary.vue"
import AddLinkUrl from "./Fields/AddLinkUrl.vue"
import AddYouTubeMission from "./Fields/AddYouTubeMission.vue"

import AddMeetingAnnouncement from "./Fields/AddMeetingAnnouncement.vue"
import AddQuestions from "./AddQuestions.vue"
import AddActivityPanelUtility from "./AddActivityPanelUtility.vue"

export default {
  name: "AddActivityPanel",
  components: {
    AddQuestions,
    AddActivityPanelUtility,
    AddPictionary,
    AddLinkUrl,
    AddYouTubeMission,
    AddMeetingAnnouncement,
    WeveTextFieldWithValidation,
    WeveTextFieldButtonGroup,
    WeveButton,
    ValidationObserver
  },
  props: {
    quickAddEnabled: {
      type: Boolean
    }
  },
  mixins: [AddMissionMixin],
  data() {
    return {
      addActivityTab: "questions"
    }
  },
  computed: {
    ...mapGetters({
      mission: "getCurrentMission",
      game: "game"
    }),
    currentMissionName() {
      return this.mission?.name
    }
  },
  methods: {
    countCharacters
  }
}
