const Mission = require("../Mission")
const MissionType = require("../enums/Mission")

class Topic extends Mission {
  constructor(props) {
    super(props)
    this.behavior = MissionType.Info
    this.time = 120
    this.points = 0
    this.numOfTries = 1

    this.setDefaultModes()
  }
}

module.exports = Topic
