<template>
  <CustomDrawer
    v-model="state.value"
    :show-sub-menu="showSubMenu"
    @updateShowSubMenu="updateShowSubMenu"
    :offsetY="offsetY"
    menu-background="#1E2126"
    sub-menu-background="#1E2126"
    v-bind="$attrs"
    :isLoading="isLoading"
    :full-height="isUserView"
  >
    <template #menu-content>
      <AddActivityPanel
        v-if="currentPanel === pannelNames.AddActivities"
        @showSubMenu="showSubPanel"
        @closeMenu="addActivityVisibility = false"
        @loading="setLoadingState"
      >
        <template #back-button>
          <v-btn @click="showPanel('')" class="pl-1" flat>
            <v-icon> keyboard_arrow_left </v-icon>
            <h2 class="white--text">Add New Activity</h2>
          </v-btn>
          <div class="white--text ml-3">
            Activity will be after ‘{{ mission.name || "" }}’
          </div>
        </template>
      </AddActivityPanel>

      <ActivityListPanel
        v-else
        @loading="setLoadingState"
        @showPanel="showPanel"
      />
    </template>

    <template #submenu-content>
      <AddPoll
        v-if="currentSubPanel === pannelNames.Poll"
        @loading="setLoadingState"
        @back="back"
      />

      <AddCategories
        v-if="currentSubPanel === pannelNames.Categories"
        @loading="setLoadingState"
        @back="back"
      />

      <AddMultipleChoice
        v-if="currentSubPanel === pannelNames.MultipleChoice"
        @loading="setLoadingState"
        @back="back"
      />
    </template>
  </CustomDrawer>
</template>
<script>
import { delay } from "lodash"
import { mapGetters } from "vuex"

import { RtbControlButton } from "@/components/ui"

import Role from "@shared/enums/Role"

import CustomDrawer from "@/components/CustomDrawer.vue"
import AddActivityPanel from "@/components/ActivityMenuPanels/AddActivityPanel.vue"
import AddPoll from "@/components/ActivityMenuPanels/AddPoll.vue"
import AddMultipleChoice from "@/components/ActivityMenuPanels/AddMultipleChoice.vue"
import AddCategories from "@/components/ActivityMenuPanels/AddCategories.vue"
import ActivityListPanel from "@/components/ActivityMenuPanels/ActivityListPanel.vue"
import { PannelNames } from "@/components/ActivityMenuPanels/ActivityMenuPanels.consts"
import state from "./index"

export default {
  name: "ActivityDrawer",
  components: {
    CustomDrawer,
    AddMultipleChoice,
    AddPoll,
    AddCategories,
    AddActivityPanel,
    ActivityListPanel,
    RtbControlButton
  },
  data() {
    return {
      currentPanel: "",
      currentSubPanel: "",
      showSubMenu: false,
      addActivityVisibility: false,
      isLoading: false,
      state
    }
  },
  watch: {
    isComponentVisible(value) {
      if (!value && this.isLoading) this.isLoading = false
    }
  },
  computed: {
    ...mapGetters({ mission: "getCurrentMission" }),
    ...mapGetters(["gameStatus"]),
    isUserView() {
      return (
        this.$store.getters["auth/user"]?.role === Role.PLAYER &&
        !this.$store.getters["auth/isModerator"]
      )
    },
    pannelNames() {
      return PannelNames
    },
    isComponentVisible() {
      return this.state.value || this.showSubMenu
    },
    offsetY() {
      if (this.isUserView) {
        return 0
      }

      return 40
    }
  },
  methods: {
    setLoadingState(val) {
      this.isLoading = val
    },
    updateShowSubMenu(val) {
      this.showSubMenu = val
    },
    showPanel(panel) {
      this.addActivityVisibility = false
      delay(() => (this.addActivityVisibility = true), 150)
      this.currentPanel = panel
    },
    showSubPanel(panel) {
      this.showSubMenu = false
      delay(() => (this.showSubMenu = true), 150)
      this.currentSubPanel = panel
    },
    back() {
      this.showSubMenu = false
      this.addActivityVisibility = true
    }
  }
}
</script>
