const Mission = require("../Mission")
const MissionType = require("../enums/Mission")

class Link extends Mission {
  constructor(props) {
    super(props)
    this.points = 0
    this.time = 60
    this.behavior = MissionType.Link

    this.setDefaultModes()
  }
}

module.exports = Link
