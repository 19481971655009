var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "px-3 mt-3" },
    [
      _c("AddMeetingAnnouncement", { staticClass: "mb-2" }),
      _c("AddLinkUrl", { staticClass: "mb-2" }),
      _c("hr", { staticClass: "divider my-2" }),
      _c(
        "div",
        {
          staticClass: "relative mb-2",
          staticStyle: { "min-height": "500px" },
        },
        [
          _c("ValidationObserver", {
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var invalid = ref.invalid
                  return [
                    _vm._l(5, function (i) {
                      return _c(
                        "div",
                        { key: i, staticClass: "my-2" },
                        [
                          _c("WeveTextFieldWithValidation", {
                            attrs: {
                              label: "Topic " + i,
                              placeholder: "Topic " + i,
                              maxlength: "50",
                              help: "",
                              "characters-counter": "",
                              rules: "required|max:50",
                            },
                            model: {
                              value: _vm.topics[i],
                              callback: function ($$v) {
                                _vm.$set(_vm.topics, i, $$v)
                              },
                              expression: "topics[i]",
                            },
                          }),
                        ],
                        1
                      )
                    }),
                    _c(
                      "div",
                      { staticClass: "absolute right-0 bottom-0" },
                      [
                        _c(
                          "v-btn",
                          {
                            staticClass: "black--text font-weight-bold",
                            attrs: {
                              round: "",
                              color: "#ffd44c",
                              disabled: invalid,
                            },
                            on: { click: _vm.addTopics },
                          },
                          [_vm._v(" ADD ")]
                        ),
                      ],
                      1
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }