





































import Vue from "vue"
import { ValidationObserver } from "vee-validate"
import WeveTextFieldWithValidation from "@/components/WeveTextFieldWithValidation.vue"
import WeveTextFieldButtonGroup from "@/components/WeveTextFieldButtonGroup.vue"
import AddLinkUrl from "./Fields/AddLinkUrl.vue"
import AddMeetingAnnouncement from "./Fields/AddMeetingAnnouncement.vue"

import TopicMission from "@shared/missions/Topic"

export default Vue.extend({
  name: "AddActivityPanelUtility",
  components: {
    AddLinkUrl,
    AddMeetingAnnouncement,
    ValidationObserver,
    WeveTextFieldWithValidation,
    WeveTextFieldButtonGroup
  },
  data() {
    return {
      topics: ["", "", "", "", ""]
    }
  },
  methods: {
    async addTopics() {
      const missions = this.topics.map(
        string =>
          new TopicMission({
            name: string.substr(0, 40),
            instructions: string
          })
      )
      this.$_AddMissionMixin_add(...missions)
    }
  }
})
