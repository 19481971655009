<template>
  <div class="activity-list-panel">
    <ActivityPanelLocker />
    <div class="activity-list-panel__header">Add Activity Set</div>
    <template v-if="hasAdvancedControls">
      <div class="activity-list-panel__subtitle">
        Activity set will be added after current activity
      </div>

      <WeveButton
        class="my-2"
        size="sm"
        variant="primary"
        block
        @click="$emit('showPanel', pannelNames.AddActivities)"
      >
        <template #prepend>
          <v-icon class="black--text"> add_circle </v-icon>
        </template>
        ADD NEW ACTIVITY
      </WeveButton>
      <hr class="divider my-3" />
      <div class="d-flex mb-3">
        <v-flex xs11 shrink>
          <WeveTextField
            v-if="userType !== UserType.PLAYER"
            v-model="query"
            label="Search"
            hide-label
            placeholder="Search..."
          />
        </v-flex>
        <v-flex d-flex xs1 shrink>
          <button class="ml-2" @click="toggleFilters">
            <v-icon
              :style="{
                color: filters ? '#8680ff' : '#DADADA'
              }"
              >filter_list</v-icon
            >
          </button>
        </v-flex>
      </div>
      <template v-if="filters">
        <v-layout style="align-items: flex-end" class="flex-grow-0" flex>
          <WeveSelectField
            class="mb-3"
            style="width: 100%"
            label="Type"
            placeholder="Filter by type"
            :options="types"
            v-model="type"
          />
          <v-btn
            v-if="type !== null"
            style="min-width: 20px; margin: 18px 5px; padding: 0 10px"
            class="xs1"
            flat
            @click="type = null"
          >
            <SvgIcon size="small" name="times-regular" />
          </v-btn>
        </v-layout>
        <v-layout class="flex-grow-0 mb-2" style="position: relative">
          <v-layout
            flex
            align-center
            style="position: absolute; right: 0px; top: -20px"
          >
            <div class="mr-2 pt-3">Mine</div>
            <v-switch
              class="activity-list-panel__me-switch"
              color="#8680ff"
              style="height: 22.5px; padding: 0"
              v-model="mine"
            />
          </v-layout>
          <v-layout style="width: 100%; align-items: flex-end" flex>
            <WeveSelectField
              style="width: 100%"
              class="xl11 mb-1"
              placeholder="Filter by user"
              v-model="userID"
              label="Owner"
              :options="users"
              option-label="fullname"
              option-value="id"
              :getOptionKey="option => option.id"
              @search:focus="fetchUsers"
            >
              <template #option="option">
                <img
                  v-if="option.image"
                  :src="option.image"
                  :alt="`${option.label} avatar`"
                  class="w-2 h-2 rounded-full mr-1 object-cover"
                />
                <span class="relative" style="top: 2px">
                  {{ option.fullname }}
                </span>
              </template>
              <template #selected-option="{ item: option }">
                <img
                  v-if="option.image"
                  :src="option.image"
                  :alt="`${option.label} avatar`"
                  class="w-8 h-8 rounded-full mr-1 object-cover"
                />
                <span class="relative" style="top: 2px">
                  {{ option.fullname }}
                </span>
              </template>
            </WeveSelectField>
            <v-btn
              v-if="userID !== null"
              style="min-width: 20px; margin: 5px; padding: 0 10px"
              class="xs1"
              flat
              @click="userID = null"
            >
              <SvgIcon size="small" name="times-regular" />
            </v-btn>
          </v-layout>
        </v-layout>
      </template>
    </template>
    <div class="activity-items mt-2">
      <div
        v-for="set in activitySetsWithStatus"
        :key="set.id"
        class="activity-item"
        :class="{
          'activity-item--played': set.played,
          'activity-item--imported': set.imported,
          'activity-item--available': !set.imported
        }"
        @click="set.imported && navigateToSet(set.id)"
      >
        <div class="activity-item__duration">
          {{ formatTime(set.duration) }}
        </div>
        <div class="activity-item__name">
          {{ set.name }}
        </div>

        <WeveButton
          v-if="!set.imported"
          class="activity-item__btn ml-1"
          size="sm"
          variant="primary"
          @click="!set.imported && importSet(set.id, set.name)"
        >
          <template #prepend>
            <v-icon class="black--text"> add_circle </v-icon>
          </template>
          ADD
        </WeveButton>

        <SvgIcon
          v-else
          @click.stop="restartSet(set.id)"
          class="mr-2 red--text cursor-pointer activity-item__trash-icon"
          style="cursor: pointer"
          name="replay"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue"
import { mapGetters } from "vuex"
import { format, addMilliseconds } from "date-fns"
import { debounce, chain } from "lodash"
import { db, Event as FirebaseEvent } from "@/firebase"

import { WeveTextField, WeveSelectField, WeveButton } from "@weve/ui"

import NavigationService from "@/services/navigation.service"
import HubSpot from "@/services/hubspot.service"
import {
  NotificationMixin,
  NotificationType,
  NotificationScope
} from "@/mixins/NotificationMixin"

import { Role } from "@/helpers"

const normalize = string => String(string).toLowerCase().trim()

import Type from "@shared/enums/Activity"
import AddMissionMixin from "@/components/ActivityMenuPanels/AddMissionMixin"
import Collection from "@shared/Collection"
import { PannelNames } from "@/components/ActivityMenuPanels/ActivityMenuPanels.consts"
import ActivityPanelLocker from "@/components/ActivityMenuPanels/ActivityPanelLocker.vue"
const UserType = {
  ADMIN: "admin",
  HOST: "host",
  PLAYER: "player"
}

const SCOPE = {
  [UserType.ADMIN]: Object.values(Type),
  [UserType.HOST]: [Type.BLOCK, Type.ON_THE_FLY, Type.ACTIVITY],
  [UserType.PLAYER]: [Type.ACTIVITY]
}

export default Vue.extend({
  name: "ActivityListPanel",
  components: {
    ActivityPanelLocker,
    WeveTextField,
    WeveSelectField,
    WeveButton
  },
  mixins: [AddMissionMixin, NotificationMixin],
  data() {
    return {
      UserType,
      type: null,
      activitySets: [],
      query: null,
      debouncedQuery: "",
      import: null,
      userID: null,
      users: [],
      filters: false
    }
  },
  async created() {
    this.$_AddMissionMixin_setLoadingIndicator(true)

    const snapshot = await db
      .ref(`org/${this.orgID}/games`)
      .orderByChild("importable")
      .equalTo(true)
      .limitToFirst(1000)
      .once("value")

    this.type = this.types[0]

    this.activitySets = Collection.normalize(snapshot.val() || {}).map(set => {
      set.name = set.externalName || set.name
      return set
    })

    this.$_AddMissionMixin_setLoadingIndicator(false)
  },
  beforeDestroy() {
    this.importRef?.off(FirebaseEvent.VALUE, this.onImportUpdate)
  },
  watch: {
    filters(value) {
      if (value === false) this.resetFilters()
    },
    query: debounce(function (value) {
      this.debouncedQuery = value || ""
    }, 500),
    importRef: {
      handler(newValue, oldValue) {
        oldValue?.off(FirebaseEvent.VALUE, this.onImportUpdate)
        newValue?.on(FirebaseEvent.VALUE, this.onImportUpdate)
      },
      immediate: true
    }
  },
  computed: {
    ...mapGetters(["orgID", "actualGameID", "plays", "actualGame"]),
    ...mapGetters("auth", ["user", "isModerator", "isHost"]),
    hasAdvancedControls() {
      return !this.isModerator && this.isHost
    },
    pannelNames() {
      return PannelNames
    },
    userType() {
      if (this.user?.super) {
        return UserType.ADMIN
      } else if (this.user?.role === Role.Host) {
        return UserType.HOST
      } else {
        return UserType.PLAYER
      }
    },
    types() {
      return SCOPE[this.userType]
    },
    mine: {
      get() {
        return this.userID === this.user?.id
      },
      set(value) {
        const user = this.user
        this.userID = value ? user.id : null
        this.users = [
          { ...user, fullname: `${user.firstname} ${user.lastname}` }
        ]
      }
    },
    importRef() {
      return this.actualGameID
        ? db
            .auxiliary()
            .ref(`org/${this.orgID}/game/${this.actualGameID}/import`)
        : null
    },
    playsGroupedByOrigin() {
      return this.plays.reduce((acc, val) => {
        if (!val?.missionOriginID) return acc
        if (acc[val.missionOriginID]) {
          acc[val.missionOriginID].push(val)
        } else {
          acc[val.missionOriginID] = [val]
        }
        return acc
      }, {})
    },
    activitySetsWithStatus() {
      const playsGroupedByOrigin = this.playsGroupedByOrigin || {}
      const sets = this.import || {}

      return chain(
        this.activitySetsFiltered.map((set, index) => ({
          ...set,
          name: set.externalName || set.name || `Activity ${index + 1}`,
          played: !!playsGroupedByOrigin[set.id],
          imported: !!sets[set.id]
        }))
      )
        .sortBy(set => set.duration)
        .sortBy(set => !set.imported)
        .sortBy(set => !set.played)
        .value()
    },
    predicates() {
      const predicates = []
      if (this.userID) predicates.push(set => set.ownerID === this.userID)
      if (this.type) predicates.push(set => set.runStatus === this.type)
      if (this.debouncedQuery)
        predicates.push(set =>
          normalize(set.name).includes(normalize(this.debouncedQuery))
        )
      return predicates
    },
    activitySetsFiltered() {
      const predicates = this.predicates
      return this.activitySets.filter(set =>
        predicates.every(predicate => predicate(set))
      )
    }
  },
  methods: {
    toggleFilters() {
      this.filters = !this.filters
    },
    resetFilters() {
      this.query = ""
      this.type = null
      this.mine = false
      this.userID = null
    },
    async fetchUsers() {
      console.log("fetchUsers")
      const snapshot = await db
        .ref(`org/1/users`)
        .orderByChild("role")
        .equalTo(Role.Host)
        .once("value")

      this.users = Object.entries(snapshot.val() || {}).map(([id, _obj]) => {
        const obj = _obj || {}
        obj.id = id
        obj.fullname = `${obj.firstname} ${obj.lastname}`
        return obj
      })
    },
    onImportUpdate(snapshot) {
      this.import = snapshot?.val()
    },
    async deleteSet(gameID) {
      this.$_AddMissionMixin_setLoadingIndicator(true)
      try {
        await NavigationService.deleteSet(this.orgID, this.actualGameID, gameID)
        await this.$info("The Activity Set has been removed from the game")

        if (this.isModerator) return

        await this.$_NotificationMixin_send({
          addText: this.getSetNameByID(gameID),
          type: NotificationType.REMOVE_ACTIVITY_SET,
          scope: NotificationScope.GLOBAL
        })
      } catch (e) {
        console.error(e)
        await this.$info(e?.message || "Failure")
      }
      this.$_AddMissionMixin_setLoadingIndicator(false)
    },
    async importSet(gameID, name) {
      this.$_AddMissionMixin_setLoadingIndicator(true)
      try {
        await NavigationService.importSet(
          this.orgID,
          this.actualGameID,
          this.orgID,
          gameID
        )

        HubSpot.push([
          "trackCustomBehavioralEvent",
          {
            name: "pe2803895_app_weve_com___import_set",
            properties: {
              activity_title: name,
              activity_id: gameID
            }
          }
        ])

        const game = this.activitySets.find(({ id }) => id === gameID)
        const normalize = string => (string ? ` ${string}` : "")
        const name = normalize(game.externalName || game.name || "")
        const duration = game.duration / 60 / 1000

        await this.$_NotificationMixin_send({
          addText: name,
          type: NotificationType.START_ACTIVITY_SET,
          scope: NotificationScope.GLOBAL
        })

        const message =
          this.user?.role === Role.Player
            ? `You have added a ${duration} minute activity set called ${name}. Have fun!`
            : `You have added a ${duration} minute activity set called ${name}. Use the space bar to get started!`

        await this.$info(message)
      } catch (e) {
        console.error(e)
        await this.$info(e?.message || "Failure")
      }

      this.$_AddMissionMixin_setLoadingIndicator(false)
    },
    getSetByID(gameID) {
      return this.activitySets.find(({ id }) => id === gameID)
    },
    getSetNameByID(gameID) {
      const game = this.getSetByID(gameID)
      return game.externalName || game.name || ""
    },
    formatTime(ms) {
      return ms ? format(addMilliseconds(new Date(0), ms), "m:ss") : "5:00"
    },
    async navigateToSet(setID) {
      this.$_AddMissionMixin_setLoadingIndicator(true)
      try {
        const mission = Object.values(this.$store.getters.missions ?? {})
          .sort((a, b) => a.pos - b.pos)
          .find(mission => mission.originID === setID)
        if (!mission) throw new Error(`Oops. Something went wrong.`)
        await NavigationService.navigateTo(
          this.orgID,
          this.actualGameID,
          mission?.id
        )
      } catch (e) {
        await this.$info(e?.message || "Failure")
      }
      this.$_AddMissionMixin_setLoadingIndicator(false)
    },
    async restartSet(setID) {
      this.$_AddMissionMixin_setLoadingIndicator(true)

      try {
        await this.$confirm({
          message: "Are you sure you want to restart the Activity Set?",
          buttonColor: "danger",
          yesText: "Yes, restart"
        })

        await NavigationService.reset(
          this.orgID,
          this.actualGameID,
          null,
          setID
        )
      } catch (e) {
        console.error(e)
      }
      this.$_AddMissionMixin_setLoadingIndicator(false)
    }
  }
})
</script>
<style lang="scss">
.activity-list-panel {
  $block: &;
  background: #27272a;
  height: 100%;
  padding: 24px 14px 6px;
  display: flex;
  flex-direction: column;
  position: relative;
  color: white;

  .weve-text-input__top {
    display: none;
  }

  .vs__dropdown-option {
    display: flex;
    align-items: center;
  }

  &__progress-linear {
    margin-top: 0;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    border-radius: 4px;
    z-index: 5;
  }

  &__me-switch {
    .v-input--switch__track {
      border-radius: 20px;
      height: 20px;
      left: 2px;
      opacity: 0.6;
      position: absolute;
      right: 2px;
      top: calc(50% - 10px);
      width: 40px;
    }
  }

  &__me-switch.v-input--switch.v-input--is-dirty
    .v-input--selection-controls__ripple,
  &__me-switch.v-input--switch.v-input--is-dirty .v-input--switch__thumb {
    transform: translate(22px, 0) !important;
  }

  &::before {
    #{$block}--locked & {
      content: "";
      display: block;
      background-color: #000;
      opacity: 0.7;
      z-index: 4;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
    }
  }

  &__header {
    font-size: 22px;
    line-height: 1;
    font-weight: 900;
    padding-left: 6px;
    padding-right: 6px;
    margin-bottom: 22px;
  }

  &__subtitle {
    color: #9aa1ad;
  }
}

.activity-items {
  height: 0;
  flex: 1;
  overflow-y: auto;

  .activity-item {
    height: 48px;

    &__btn {
      visibility: hidden;
      transition: none;
      opacity: 0;
    }

    &:hover {
      .activity-item__btn {
        visibility: visible;
        opacity: 1;
      }
    }
  }
}

.activity-item {
  $block: &;
  border-radius: 4px;
  padding: 4px;
  display: flex;
  align-items: center;
  font-size: 14px;
  margin-bottom: 10px;
  transition: background-color 0.3s;

  &--imported {
    background-color: #8680ff;
  }

  &--played {
    filter: grayscale(80%) contrast(0.6);
  }

  &--available:hover {
    background-color: #393b42;
  }

  &__trash-icon {
    width: 20px;
    height: 20px;
    color: white !important;
    margin-right: 5px;
  }

  &__duration {
    width: 38px;
    height: 38px;
    display: flex;
    justify-content: center;
    align-items: center;
    letter-spacing: -0.03em;
    padding-bottom: 2px;
  }

  &__name {
    flex: 1;
    margin-left: 12px;
    line-height: 1.3;
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    max-height: 38px;

    &::before {
      display: block;
      content: attr(title);
      font-weight: bold;
      height: 0;
      width: 0;
      overflow: hidden;
      visibility: hidden;
    }
  }
}
</style>
