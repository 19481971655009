













import Vue from "vue"
import WeveTextFieldButtonGroup from "@/components/WeveTextFieldButtonGroup.vue"

import LinkMission from "@shared/missions/Link"
import AddMissionMixin from "@/components/ActivityMenuPanels/AddMissionMixin"
export default Vue.extend({
  name: "AddLinkUrl",
  components: { WeveTextFieldButtonGroup },
  mixins: [AddMissionMixin],
  methods: {
    async addLink(linkURL) {
      const mission = new LinkMission({
        name: "Link Added",
        title: "Your Link",
        answer: linkURL,
        instructions: "Here's your link"
      })
      await this.$_AddMissionMixin_add(mission)
    }
  }
})
