














import Vue from "vue"
import { mapActions, mapGetters } from "vuex"
import WeveTextFieldButtonGroup from "@/components/WeveTextFieldButtonGroup.vue"

import AddMissionMixin from "@/components/ActivityMenuPanels/AddMissionMixin"
export default Vue.extend({
  name: "AddMeetingAnnouncement",
  components: { WeveTextFieldButtonGroup },
  mixins: [AddMissionMixin],
  computed: {
    ...mapGetters(["game", "gameID"]),
    anouncement() {
      return this.game?.meetingAnnouncement
    }
  },
  methods: {
    ...mapActions("Games", ["updateGameAny"]),
    async saveAnnouncement(announcementText) {
      await this.updateGameAny({
        theKey: this.gameID,
        meetingAnnouncement: announcementText
      })
    }
  }
})
